import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

import '../utils/normalize.css'
import '../utils/css/screen.css'

const AboutPage = ({ data }, location) => {
  const about = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="About"
        keywords={[`portfolio`, `gatsby`, `javascript`, `react`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <div className="container">
            <h2>{about.frontmatter.headline}</h2>
          </div>
          <figure className="kg-card kg-image-card">
            <Img
              fluid={about.frontmatter.thumbnail.childImageSharp.fluid}
              className="kg-image"
              style={{ transform: 'translateY(-7px)' }}
            />
            {/* <figcaption>Large imagery is at the heart of this theme</figcaption> */}
          </figure>
          <div dangerouslySetInnerHTML={{ __html: about.html }} />
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: "/about/" } }) {
      html
      frontmatter {
        headline
        updated
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
